@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .w-xfit {
    width: fit-content;
  }
  .h-fit{
    height: fit-content;
  }
}

  

